@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'FilsonPro-Regular';
  src: url('../src/fonts/FilsonPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

p{
  margin-bottom: 0;
}

.no-list-style{
  list-style: none;
}


.noise{
  background-image: url('../public/assets/texture.png');
}

.left-text{
  text-align: left!important;
}